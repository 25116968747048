import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutPhoenixCustom"
import BgImg from "../../../images/TheBig10_Big.jpg"
import TBT from "../../../images/TheBig10_Square.jpg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
    imageUrl,
    imageAlt,
    sermonTitle,
    sermonLink,
    date,
    badge,
    pastor,
    sermonSeries,
    badgeColor,
}) {
    return (
        <Box
            as={Link}
            to={sermonLink}
            w="auto"
            h="auto"
            borderWidth="1px"
            rounded="lg"
            overflow="hidden"
            bg="#edf2f7"
            boxShadow="md"
            css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
        >
            <Image src={imageUrl} alt={imageAlt} w="100%" />

            <Box p="6">
                <Box alignItems="baseline">
                    <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
                        {badge}
                    </Badge>
                    <Box
                        color="gray.500"
                        fontWeight="semibold"
                        letterSpacing="wide"
                        fontSize="xs"
                        textTransform="uppercase"
                        mt="3"
                    >
                        {sermonSeries} &bull; {pastor}
                    </Box>
                </Box>
                <Box
                    fontWeight="bold"
                    as="h2"
                    lineHeight="tight"
                    isTruncated
                    color="gray.900"
                >
                    {sermonTitle}
                </Box>

                <Box color="gray.600" fontSize="sm">
                    {date}
                </Box>
            </Box>
        </Box>
    )
}

function SermonBoxExternal({
    imageUrl,
    imageAlt,
    sermonTitle,
    sermonLink,
    date,
    badge,
    pastor,
    sermonSeries,
    badgeColor,
}) {
    return (
        <Box
            as="a"
            href={sermonLink}
            w="auto"
            h="auto"
            borderWidth="1px"
            rounded="lg"
            overflow="hidden"
            bg="#edf2f7"
            boxShadow="md"
            css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
        >
            <Image src={imageUrl} alt={imageAlt} w="100%" />

            <Box p="6">
                <Box alignItems="baseline">
                    <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
                        {badge}
                    </Badge>
                    <Box
                        color="gray.500"
                        fontWeight="semibold"
                        letterSpacing="wide"
                        fontSize="xs"
                        textTransform="uppercase"
                        mt="3"
                    >
                        {sermonSeries} &bull; {pastor}
                    </Box>
                </Box>
                <Box
                    fontWeight="bold"
                    as="h2"
                    lineHeight="tight"
                    isTruncated
                    color="gray.900"
                >
                    {sermonTitle}
                </Box>

                <Box color="gray.600" fontSize="sm">
                    {date}
                </Box>
            </Box>
        </Box>
    )
}

const IndexPage = () => (
    <Layout
        ImgSrc={BgImg}
        MainText="The Big 10"
        SecondText="Check out sermons from our The Big 10 sermon series"
    >
        <SEO title="Sermons - The Big 10" />
        <Grid
            templateColumns={[
                "repeat(1, 1fr)",
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
            ]}
            gap={10}
        >
            <SermonBox
                imageUrl={TBT}
                imageAlt="The Big 10 Sermon Series"
                sermonTitle="Setting Goals"
                sermonSeries="The Big 10"
                sermonLink="/phoenix/sermons/setting-goals"
                date="November 26th, 2023"
                pastor="Pastor Andrew"
            />
            <SermonBox
                imageUrl={TBT}
                imageAlt="The Big 10 Sermon Series"
                sermonTitle="Nothing but the Truth"
                sermonSeries="The Big 10"
                sermonLink="/phoenix/sermons/nothing-but-the-truth"
                date="November 19th, 2023"
                pastor="Pastor Dave"
            />
            <SermonBox
                imageUrl={TBT}
                imageAlt="The Big 10 Sermon Series"
                sermonTitle="Is That Mine?"
                sermonSeries="The Big 10"
                sermonLink="/phoenix/sermons/is-that-mine"
                date="November 12th, 2023"
                pastor="Pastor Andrew"
            />
            <SermonBox
                imageUrl={TBT}
                imageAlt="The Big 10 Sermon Series"
                sermonTitle="No Adultery"
                sermonSeries="The Big 10"
                sermonLink="/phoenix/sermons/no-adultery"
                date="November 5th, 2023"
                pastor="Pastor Andrew"
            />
            <SermonBox
                imageUrl={TBT}
                imageAlt="The Big 10 Sermon Series"
                sermonTitle="Do Not Murder"
                sermonSeries="The Big 10"
                sermonLink="/phoenix/sermons/do-not-murder"
                date="October 29, 2023"
                pastor="Pastor Andrew"
            />
            <SermonBox
                imageUrl={TBT}
                imageAlt="The Big 10 Sermon Series"
                sermonTitle="Honor Your Dad and Mom"
                sermonSeries="The Big 10"
                sermonLink="/phoenix/sermons/honor-your-dad-and-mom"
                date="October 22, 2023"
                pastor="Pastor Andrew"
            />
            <SermonBox
                imageUrl={TBT}
                imageAlt="The Big 10 Sermon Series"
                sermonTitle="Keep the Sabbath"
                sermonSeries="The Big 10"
                sermonLink="/phoenix/sermons/keep-the-sabbath"
                date="October 15th, 2023"
                pastor="Scott Trefny"
            />
            <SermonBox
                imageUrl={TBT}
                imageAlt="The Big 10 Sermon Series"
                sermonTitle="Watch Your mouth"
                sermonSeries="The Big 10"
                sermonLink="/phoenix/sermons/watch-your-mouth"
                date="October 8th, 2023"
                pastor="Pastor Andrew"
            />
            <SermonBox
                imageUrl={TBT}
                imageAlt="The Big 10 Sermon Series"
                sermonTitle="What am I Worshipping?"
                sermonSeries="The Big 10"
                sermonLink="/phoenix/sermons/what-am-i-worshipping"
                date="October 1st, 2023"
                pastor="Pastor Andrew"
            />
            <SermonBox
                imageUrl={TBT}
                imageAlt="The Big 10 Sermon Series"
                sermonTitle="Outdated or Timeless"
                sermonSeries="The Big 10"
                sermonLink="/phoenix/sermons/outdated-or-timeless"
                date="September 24th, 2023"
                pastor="Pastor Andrew"
            />
            <SermonBox
                imageUrl={More}
                imageAlt="view more sermons"
                sermonTitle="View More Sermons"
                sermonLink="/phoenix/sermons"
                sermonSeries="All Sermons"
                pastor="Cross Church Phoenix"
            />
        </Grid>
    </Layout>
)

export default IndexPage
